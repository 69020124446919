import { useMantineTheme } from '@mantine/core';
import React from 'react';
import { ContestStatus } from '@betterpool/api-types/contests-service';

import dt from '~/testing';

import useStyles from './ContestCard.styles';
import type { ContestCardProps } from './types';
import CardContainer from './CardContainer';
import ContestCardProgressBar from './ContestCardProgressBar';
import ContestCardHeader from './ContestCardHeader';
import ContestCardFooter from './Footer/ContestCardFooter';
import ContestCardBanner from './ContestCardBanner';
import { ContestCardActions } from './ContestCardActions';

function ContestCard({
  contest,
  currentSlateStatus,
  isContestThumbnailShown,
  isNotAllowedInLocation,
  isBadgeIconSectionEnabled = true,
  isBannerEnabled = false,
  isCardMenuVisible = true,
  isCountdownPillEnabled = false,
  isSurvivor = false,
  isMyContestsView = false,
  isInvitesManagerEnabled = false,
  isMassCommunicationEnabled = false,
  actionBanner,
  onClick,
  leaderboard,
  survivor,
  isStatic,
  promoSource,
  promoIndex,
}: ContestCardProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles({ isStatic });
  const contestCardBg = (() => {
    if (isNotAllowedInLocation) {
      return theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.gray[7];
    }

    return undefined;
  })();

  return (
    <CardContainer
      p="md"
      shadow={isStatic ? 'none' : 'sm'}
      bg={contestCardBg}
      radius="md"
      isMyContestsView={isMyContestsView}
      className={classes.container}
      data-test-id={dt.common.components.contestCard}
      contest={contest}
      onClick={onClick}
      isStatic={isStatic}
      promoSource={promoSource}
      promoIndex={promoIndex}
      currentSlateStatus={currentSlateStatus}
    >
      <ContestCardBanner
        contest={contest}
        isBannerEnabled={isBannerEnabled}
        isCountdownPillEnabled={isCountdownPillEnabled}
      />
      <ContestCardHeader
        contest={contest}
        isBadgeIconSectionEnabled={isBadgeIconSectionEnabled}
        isCardMenuVisible={isCardMenuVisible}
        isContestThumbnailShown={isContestThumbnailShown}
      />
      <ContestCardProgressBar leaderboard={leaderboard} contest={contest} isSurvivor={isSurvivor} />
      <ContestCardFooter
        winning={leaderboard?.userPayout}
        userSegmentedPayouts={leaderboard?.userSegmentedPayouts}
        entryPrice={contest.entry_fee}
        isSurvivor={isSurvivor}
        contest={contest}
        userAliveEntries={survivor?.userAliveEntries}
        actionBanner={actionBanner}
        isSettled={contest.status === ContestStatus.FINALIZED}
        isMyContestsView={isMyContestsView}
        totalEntries={leaderboard?.totalSpots}
        winningEntries={leaderboard?.payoutSpots}
      />
      {(isInvitesManagerEnabled || isMassCommunicationEnabled) && (
        <ContestCardActions
          contestId={contest.id}
          isInvitesManagerEnabled={isInvitesManagerEnabled}
          isMassCommunicationEnabled={isMassCommunicationEnabled}
        />
      )}
    </CardContainer>
  );
}

export default ContestCard;
