export default {
  slip: {
    picks__hasPlural: {
      one: '1 pick',
      other: '{{count}} picks',
    },
    waitingForMatch: 'Waiting for match',
    winUpTo: 'Win up to',
    won: 'Won',
  },
  slips: {
    empty: {
      active: {
        title: 'No active QuickSlips',
        subtitle: `
<p>
You do not have any active QuickPicks entries.
</p>

<p>
Tap on the Board tab to start making picks!
</p>
`,
      },
      final: {
        title: 'No final QuickPicks',
        subtitle: `
<p>
You do not have any final QuickPicks entries.
</p>

<p>
Tap on the Board tab to start making picks!
</p>
`,
      },
    },
  },
  slipDetail: {
    nav: {
      standings: 'Standings',
      detail: 'Detail',
    },
    lookingForMatchBanner: {
      title: 'Looking for match...',
      subtitle: 'Your opponents and their picks will show when matched.',
    },
    detail: {
      numberOfEntries__hasPlural: {
        one: 'Entry',
        other: 'Entries',
      },
      entryFee: 'Entry fee',
      prize: 'Prizes',
      scoring: {
        push: 'Push',
        correct: 'Correct',
        incorrect: 'Incorrect',
        dnp: 'DNP',
      },
      points__hasPlural: {
        one: '{{count}} point',
        other: '{{count}} points',
      },
      sections: {
        prize: 'Prize payouts',
        detail: 'Details',
        rules: 'Scoring & Rules',
        entrants: {
          title: 'Entrants',
          waiting: 'Your opponents will show when matched.',
        },
      },
    },
  },
  tabs: {
    board: 'Board',
    active: 'Active',
    final: 'Final',
  },
  board: {
    picksPanel: {
      viewEntry: 'View entry',
      winUpTo: 'Win up to {{payoutMultiplier}}x',
      minPicksRequired: '{{count}} picks required',
      addAtLeastAmountOfPicks: 'Add at least {{count}} picks',
      errors: {
        maxEntriesReached: 'Max of {{count}} picks allowed. Remove a pick to add another.',
      },
    },
    picksDrawer: {
      errors: {
        minPicks: 'You must select at least {{count}} picks',
        atLeastTwoTeam: 'Your picks must include players from at least 2 teams.',
        apiError: {
          title: 'Submission failed',
        },
        locationError:
          'Based on your current location, you are not currently allowed to enter this contest.',
        kycError: 'Please verify your information before entering a contest.',
      },
      close: 'Close',
      title: 'Review picks: {{count}}',
      actionsPanel: {
        selectEntryFee: 'Select an entry fee',
        winUpTo: 'Win up to:',
        footNote: 'You’ll be entered into a {{groupSize}}-entry, winner-take-all contest',
        balance: 'Balance:',
        balanceError: 'There was an error while loading your account balance',
        submit: 'Submit entry',
        submitLoading: 'Submitting...',
      },
    },
    entrySubmitted: {
      title: 'Entry submitted!',
      subtitle:
        'Your entry has been submitted. It will be matched into a {{groupSize}}-entry, winner-take-all contest.',
      picksCount: '{{count}} picks',
      winUpTo: 'Win up to:',
      picksReuse: 'Reuse picks',
      close: 'Close',
    },
    playerCard: {
      more: 'More',
      less: 'Less',
      homeVsAway: '<strong>{{homeTeam}}</strong> vs {{awayTeam}}',
      awayAtHome: '<strong>{{awayTeam}}</strong> @ {{homeTeam}}',
    },
    filters: {
      gameFilterButton: 'Filters',
      gameModal: {
        title: 'Filter by game',
        allGames: 'All games',
        submit: 'Show Picks',
      },
      searchInputPlaceholder: 'Search for players, teams or stats',
    },
    maxPicksReachedError: {
      title: 'Max picks reached',
      message: 'Max of {{count}} picks allowed. Remove a pick to add another.',
    },
    emptyScreen: {
      noProps: {
        title: 'No picks available yet',
        subtitle: 'Choose another sport or try again later.',
      },
      noSearchResults: {
        title: 'No results found',
        subtitle: 'Try another search.',
      },
    },
  },
};
