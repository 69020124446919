import { Box, Group, Text, Stack } from '@mantine/core';
import { useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { Avatar } from '~/domains/common/components/Avatar/Avatar';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import dt from '~/testing';

import { useCurrentUserCommissionerChannel } from '../../hooks/useCurrentUserCommissionerChannel';

import { HeaderWalletBalance } from './HeaderWalletBalance';

export function HeaderUserInfo() {
  const { user } = useContext(UserContext);
  const isMobile = useIsMobile();
  const { data } = useCurrentUserCommissionerChannel();

  return (
    <Group spacing="md" p={isMobile ? 'sm' : 'xl'} pb={isMobile ? 'sm' : 0} align="start">
      <Avatar
        size="lg"
        radius="xl"
        src={data?.data?.avatarUrl}
        data-test-id={dt.components.shared.header.avatar.main}
      />
      <Stack spacing={0}>
        <Text
          size="xl"
          fw={600}
          data-test-id={dt.common.components.appShellHeader.headerUserInfo.handle}
        >
          {user.username}
        </Text>
        <Text>{user.email}</Text>
        {isMobile ? (
          <Box pt="sm">
            <HeaderWalletBalance />
          </Box>
        ) : null}
      </Stack>
    </Group>
  );
}
