import { Divider, Group, Stack, createStyles } from '@mantine/core';
import React, { useContext, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { UnstyledButton } from '~/domains/design-system/Button';
import { Avatar } from '~/domains/design-system/Avatar';
import { Text } from '~/domains/design-system/Text';
import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import Dropdown from '~/domains/common/components/Dropdown/Dropdown';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';
import { RAFBanner } from '~/domains/user/domains/refer-a-friend/components/RAFBanner';
import { isBrowserFn } from '~/utils/browser';

import { Routes } from '../../../constants/routes';
import tc from '../../testing.const';
import { HeaderMenusContext } from '../HeaderMenusContext';

import useCommonStyles from './useCommonStyles';

const useStyles = createStyles((theme, _, getRef) => ({
  dropdown: {
    borderRadius: `0 0 ${theme.radius.sm}px ${theme.radius.sm}px`,
    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,
    color: theme.colors.gray2[7],
    width: 300,
    boxShadow: theme.shadows.lg,
  },
  userInfo: {
    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  userInfoText: {
    ref: getRef('userInfoText'),
  },
}));

type UserMenuProps = {
  avatarUrl?: string;
  channelHandle?: string;
  channelSlug?: string;
  email: string;
  username: string;
  isUserVerified?: boolean;
};

function UserMenu({
  avatarUrl,
  channelHandle,
  channelSlug,
  email,
  username,
  isUserVerified,
}: UserMenuProps) {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { t } = useTranslation('common');
  const { menuId, onMenuOpen } = useContext(HeaderMenusContext);
  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);
  const isReferAFriendEnabled = useIsFeatureEnabled(FEATURE.ENABLE_REFER_A_FRIEND_V2);

  const isOpen = menuId === 'user';
  const navLinks = useMemo(
    () => [
      ...(!isUserVerified
        ? [[{ href: Routes.userVerification(), label: t('appShellHeader.verifyUser') }]]
        : []),
      [
        {
          label: t('appShellHeader.createAContest'),
          href: Routes.createAContest(),
        },
        // eslint-disable-next-line no-nested-ternary
        ...(isChannelsV1Enabled && channelHandle
          ? [{ href: Routes.channel(channelHandle), label: t('appShellHeader.myChannel') }]
          : channelSlug
            ? [{ href: Routes.commissionerHub(channelSlug), label: t('appShellHeader.myChannel') }]
            : []),
      ],
      [
        {
          label: t('appShellHeader.deposit'),
          href: Routes.deposit(
            isBrowserFn()
              ? { redirectTo: `${window.location.pathname}${window.location.search}` }
              : undefined
          ),
        },
        {
          label: t('appShellHeader.withdraw'),
          href: Routes.withdraw(
            isBrowserFn()
              ? { redirectTo: `${window.location.pathname}${window.location.search}` }
              : undefined
          ),
        },
        ...(isReferAFriendEnabled
          ? [
              {
                label: '',
                href: '',
                component: (
                  <RAFBanner
                    className={commonClasses.link}
                    text={t('appShellHeader.referAFriend')}
                  />
                ),
              },
            ]
          : []),
      ],
      [
        {
          label: t('appShellHeader.myAccount'),
          href: Routes.account(),
        },
        {
          label: t('appShellHeader.signOut'),
          href: Routes.logout(),
        },
      ],
    ],
    [
      channelHandle,
      channelSlug,
      commonClasses.link,
      isChannelsV1Enabled,
      isReferAFriendEnabled,
      isUserVerified,
      t,
    ]
  );

  return (
    <Dropdown
      dropdownPosition="bottom-end"
      dropdownClassName={classes.dropdown}
      dropdownOffset={{ x: 16 }}
      isOpen={isOpen}
      onChange={(v) => {
        onMenuOpen(v ? 'user' : undefined);
      }}
      trigger={({ ref, onClick }) => (
        <UnstyledButton
          className={commonClasses.linkIcon}
          ref={ref}
          onClick={onClick}
          data-test-id={tc.components.appShellHeader.userMenuButton}
        >
          <Avatar size={24} handle={username} src={avatarUrl} />
        </UnstyledButton>
      )}
    >
      <Stack spacing="xs" w={250}>
        <UnstyledNextLink
          href={Routes.account()}
          onClick={() => {
            onMenuOpen();
          }}
          $colorInherit
          className={classes.userInfo}
        >
          <Group mb="xs" noWrap>
            <Avatar size={40} handle={username} src={avatarUrl} />
            <Stack spacing={0} sx={{ overflow: 'hidden' }}>
              <Text
                variant="headline-large"
                truncate
                data-test-id={tc.components.appShellHeader.handle}
              >
                {username}
              </Text>
              <Text variant="body-large" truncate>
                {email}
              </Text>
            </Stack>
          </Group>
        </UnstyledNextLink>
        <Divider />
        {navLinks.map((links, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>
            <Stack spacing={4}>
              {links.map(({ label, href, component }) => {
                if (component) {
                  return component;
                }

                return (
                  <UnstyledNextLink
                    className={commonClasses.link}
                    href={href}
                    key={href}
                    onClick={() => {
                      onMenuOpen();
                    }}
                    data-test-id={tc.components.appShellHeader.menuItem}
                  >
                    {label}
                  </UnstyledNextLink>
                );
              })}
            </Stack>
            {i < navLinks.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </Dropdown>
  );
}

export default UserMenu;
