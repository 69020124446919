import { showNotification } from '@mantine/notifications';
import { useCallback, useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent } from '~/domains/analytics';
import { Routes } from '~/domains/common/constants/routes';
import { useSignupDeepLinkWebToApp } from '~/domains/analytics/branchio/useDeepLink';
import { DeepLinkType } from '~/domains/analytics/branchio/types';

export const useCopyReferralCode = ({
  referralCode,
  onClose,
}: {
  referralCode: string;
  onClose: () => void;
}) => {
  const [deepLink] = useSignupDeepLinkWebToApp(referralCode, DeepLinkType.REFER_A_FRIEND);

  const { user } = useContext(UserContext);
  // copies referral code to clipboard
  const handleCopy = useCallback(
    (value?: string) => {
      window.navigator.clipboard.writeText(value ?? referralCode);

      showNotification({
        message: `Referral code copied!`,
        color: 'green',
        autoClose: 2500,
      });
    },
    [referralCode]
  );

  // tries to share referral code via native share API, falls back to copying to clipboard
  const handleCopyLink = useCallback(async () => {
    const referralURL =
      deepLink ??
      `${window.location.origin}${Routes.signUp({
        referralCode,
      })}`;

    try {
      if (
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/iu.exec(navigator.userAgent) &&
        navigator.share
      ) {
        await navigator.share({ title: 'Sign up with my referral code!', url: referralURL });
      } else {
        handleCopy(referralURL);
      }

      onClose();

      reportEvent('Refer a friend > Share referral code', {
        referrer: user?.id,
      });
    } catch (err) {
      // Native share throws "AbortError" type when users dismisses the share modal
      // We don't want to show an error for that
      if (err.name !== 'AbortError') {
        showNotification({
          message: `Could not copy URL: ${referralURL}.`,
          color: 'red',
          autoClose: 2500,
        });
      }
    }
  }, [deepLink, handleCopy, onClose, referralCode, user?.id]);

  return { handleCopy, handleCopyLink };
};
