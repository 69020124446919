export default {
  header: {
    close: 'Close',
    startsAt: 'Starts: {{startDate}}',
    entriesAnon: 'Entries',
    entries: 'Entries: {{filled}}/{{max}}',
    entry: 'Entry',
    prizes: 'Prizes',
  },
  slatesNav: {
    slateDate: {
      tomorrow: 'Tomorrow {{formattedDate}}',
      today: 'Today {{formattedDate}}',
    },
    segment: 'Segment {{index}}',
  },
  picksBar: {
    submitLabelDefault: 'Save picks',
    depositToSubmit: 'Deposit to save picks',
    picksMade: '{{picksMade}}/{{requiredPicksCount}} picks made',
    signInToSubmit: 'Sign in to save picks',
    createNewEntry: 'Create entry: {{entryFee}}',
  },
  common: {
    makePicks__hasPlural: {
      one: 'Make pick',
      other: 'Make picks',
    },
    action: {
      dismiss: 'Dismiss',
    },
    errorSubmittingPicks: {
      title: 'There was an error while saving your picks.',
      defaultMessage: 'Unknown error',
    },
  },
  team: {
    pointsInfo: {
      points: 'Points:',
      potentialPoints: 'Potential points:',
    },
    game: {
      footer: {
        bestPick: 'Best pick',
        confidenceScore: 'Confidence points:',
      },
      header: {
        finalized: {
          title: 'FINAL',
        },
        live: {
          title: 'LIVE',
        },
        scheduled: {
          preview: 'Preview',
        },
        missedPick: 'Missed pick',
      },
      team: {
        alreadyPicked: 'Already picked',
        winProbablity: 'Win prob: {{winProbablity}}%',
      },
    },
    picksList: {
      sortBy: {
        startDate: 'Start Time',
        seed: 'Seed',
      },
    },
    picksheetNotAvailableYet: {
      title: "Oops, you're early",
      subtitle: 'This pick sheet is not yet available.<br></br>Check again later.',
    },
    notification: {
      picksLimitMet:
        "You have all of your picks made for this slate. If you'd like to change your picks you must remove them.",
      pickTeamBeforeBestPick: 'You must select a team before setting it as your best pick.',
      noChanges: 'You have not made any changes to your picks.',
    },
    entriesScheduled: {
      myEntries: 'My entries',
      entryFee: 'Entry',
      maxEntries: 'Max',
    },
    entryScheduled: {
      edit: 'Edit',
    },
    pickEm: {
      picksBar: {
        close: 'Close',
        gameStatus: {
          cancelled: 'Cancelled',
          final: 'Final',
          inProgress: 'Live',
        },
        noPick: 'No pick',
        missedPick: 'Missed pick',
      },
      slatesNav: {
        points__hasPlural: {
          one: '{{count}} point',
          other: '{{count}} points',
        },
      },
      entryOverall: {
        week: 'Week',
        segment: 'Segment',
        winLoss: 'W-L',
        points: 'Points',
      },
      standings: {
        winLoss: 'W-L',
        points: 'Points',
        empty: {
          beforeStart: 'The entries for this slate will be visible after the slate start',
          afterStart: 'There are no entries for this slate',
        },
        overall: 'Overall',
        segments: 'Segments',
        bySlateDefault: 'Slate',
        segmentItem: 'Segment {{index}}',
        pointsAbbr: 'Pts',
        potentialPointsAbbr: 'Potential',
      },
    },
    survivor: {
      stats: {
        subNav: {
          slateSummary: '{{slateLabel}} summary',
          availability: 'Team availability',
        },
        summaryLabels: {
          survivors: 'Survivors',
          eliminated: 'Eliminated',
          missedPick: 'Missed pick',
        },
      },
      availability: {
        nav: {
          available: 'Available',
          unavailable: 'Unavailable',
        },
        entry: {
          viewAll: 'View all',
          noTeams: 'No teams',
          bye: '(BYE)',
        },
        updatesNotFinal: 'Updates when current week is final',
      },
    },
    stats: {
      notAvailable: {
        slateSummary:
          '{{slateLabel}} pick summaries are not available until after the first game of the week has started or the weekly pick deadline has passed',
        availability: 'Available teams will show once the previous week is finalized',
        viewEntries: 'View Entries',
      },
      listColumns: {
        pickedPercentage: '%Picked',
        pickedCount: '#Picked',
        availableCount: 'Available this slate',
      },
      summaryValueLabels: {
        entries: 'Entries',
        percentage: 'Percentage',
      },
    },
    leadeboardWidget: {
      myEntries: 'My Entries:',
      mySurvivors: 'My entries alive:',
      entered: 'Entered:',
      entry: 'Entry:',
      won: 'Won:',
      winning: 'Winning:',
      suvivors: 'Alive:',
      tooltip__hasPlural: {
        one: 'Entry {{index}}: {{count}} pt',
        other: 'Entry {{index}}: {{count}} pts',
      },
    },
    gameStats: {
      leagueNotSupported: 'Matchup screen not available for this league',
      odds: {
        title: 'Odds',
        attributes: {
          spread: 'Spread',
          moneyline: 'Moneyline',
          win_probability: 'Implied win probablity',
        },
      },
      overview: {
        title: {
          nfl: 'Matchup',
          ncaab: 'Regular season stats',
          ncaaf: 'Matchup',
        },
        attributes: {
          ppg: 'Points per game',
          pa: 'Points against',
          fieldGoalPercentage: 'Field Goal %',
          reboundsPerGame: 'Rebounds per game',
          assistsPerGame: 'Assists per game',
          blocksPerGame: 'Blocks per game',
          stealsPerGame: 'Steals per game',
        },
      },
      injuries: {
        title: 'Injuries',
        status: {
          Questionable: 'Quest',
          Doubtful: 'Doub',
          Out: 'Out',
        },
      },
      stats: {
        lastGames_title: 'Last {{ count }} games',
        lastMeetings_title: 'Last {{ count }} meetings',
        win: 'W',
        lose: 'L',
      },
    },
    entryDetail: {
      title: 'Entry details',
      noPicksVisibleYet:
        'No picks are visible yet. All picks will be visible once the picks will be locked for this slate.',
      noPicks: 'This entry has no picks for this slate.',
      slateSublabel: {
        final: 'FINAL',
        live: 'LIVE',
      },
    },
    accessError: {
      actions: {
        viewMyEntries: 'View my entries',
        backToHome: 'Back to home',
      },
      maxEntriesReached: {
        title: 'Max entries reached',
        description: 'You have reached the maximum number of entries for this contest',
      },
      invalidEntry: {
        title: 'Invalid entry',
        description: 'You have followed an invalid link',
      },
    },
  },
  spreadDeadlineBar: {
    lock: 'Spread locks: {{date}}',
    finalized: 'Spread finalized',
  },
  picksDeadlineBar: 'Picks lock: {{label}}',
  makeYourPicksBar: 'Make your picks',
  discardChangesModal: {
    title: 'Unsaved picks',
    description: 'You have unsaved picks. Are you sure you want to discard them?',
    submit: 'Discard',
    cancel: 'Cancel',
  },
  missingBestPickSaveModal: {
    title: 'No best pick',
    description:
      'You have not selected a Best Pick. Are you sure you want to save your picks withou a Best Pick?',
    submit: 'Save picks',
    cancel: 'Continue making picks',
  },
  incompletePicksSaveModal: {
    title: 'Missing picks',
    description:
      'Some games are missing picks. Be sure to make all of your picks before the pick deadline.',
    submit: 'Save picks',
    cancel: 'Continue making picks',
  },
  spreadNotSet: {
    title: 'Spread not set',
    description:
      'You have selected a team where the spread has not been set. Are you sure you want to save your picks? When the spread is set, your pick will not change.',
    submit: 'Save picks',
    cancel: 'Cancel',
  },
  withdrawEntryModal: {
    title: 'Are you sure you want to withdraw this entry ?',
    description: 'By withdrawing your entry the entry fee will be credited back to your account.',
    submit: 'Withdraw',
    cancel: 'Cancel',
    error: {
      title: 'Unable to make a withdrawal to this entry.',
      message: 'Please try again.',
    },
    success: {
      message: 'Withdrawal was successfull.',
    },
  },
  postPickModal: {
    title__hasPlural: {
      one: "You've made your pick!",
      other: "You've made your picks!",
    },
    addNewEntries: 'Add new entries',
    viewMyEntries: 'Back to my entries',
    makeTheRestOfPicks: 'Make the rest of my picks',
    continueMakingPicks: 'Keep making picks',
  },
  layoutNonScheduled: {
    nav: {
      entries: 'My entries',
      standings: 'Standings',
      stats: 'Stats',
      detail: 'Details',
      availability: 'Availability',
    },
  },
  editSettingsModal: {
    title: 'Contest settings changed',
    description:
      'The commissioner has changed the following settings of this contest. If you would like to withdraw, you can do so on the Entry Details screen.',
  },
  editNameEditModal: {
    title: 'Edit entry name',
    field: 'Entry name',
    submit: 'Save',
    errors: {
      min: "Entry name can't be empty",
      max: "Entry name can't be longer than 30 characters",
      regex: 'Only letters, numbers, hyphen, accented characters and spaces are allowed',
    },
  },
};
