import { gtag, install } from 'ga-gtag';

import log, { logError } from '~/common/utils/log';
import type { User } from '~/services/users/types';
import { isBrowserFn } from '~/utils/browser';


// Specific conversion labels for GA4 adwords (if needded)
// eslint-disable-next-line @typescript-eslint/naming-convention
const enum CONVERSION_LABELS {
  SIGN_UP = 'qgkzCJWc7toYELTYlYAq', // Sign up
  ADD_TO_CART = 'iY2UCNmt_9oYELTYlYAq', // Join contest
  PURCHASE = 'DojcCNyt_9oYELTYlYAq', // Create contest
  BEGIN_CHECKOUT = 'NG5cCN-t_9oYELTYlYAq', // Create commish channel
}

// Conversion GTAG ID and stream ID (for conversion tracking in adwords, if used)
const GA4_GTAG_ID = process.env.NEXT_PUBLIC_GA4_GTAG_ID;
const GA4_STREAMID = process.env.NEXT_PUBLIC_GA4_STREAMID;

const initGtag = () => {
  if (!process.env.NEXT_PUBLIC_GA4_GTAG_ID) {
    logError('Google tracking - No GTAG ID found');
    return;
  }

  // Initialise GTAG
  install(GA4_GTAG_ID, { send_page_view: true });

  // Google Consent Mode (via OSANO)
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'granted',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    personalization_storage: 'denied',
    functionality_storage: 'granted',
    security_storage: 'granted',
    wait_for_update: 500,
  });
  gtag('set', 'ads_data_redaction', true);

  log('Google tracking - Initialised GTAG:', GA4_GTAG_ID);
};

const isGtagInitialised = () => isBrowserFn() && window.google_tag_manager;

const setGAuser = (user: Partial<User>) => {
  gtag('config', GA4_GTAG_ID, {
    email: user.email,
    user_id: user.id,
    user_name: user.username,
  });
};

type ConversionProperties = {
  value?: number;
  currency?: 'USD';
  transaction_id?: string;
};
const reportConversionEvent = (
  conversionLabel: CONVERSION_LABELS,
  properties: ConversionProperties = {}
) => {
  if (!isGtagInitialised) {
    logError('Google tracking - GTAG not initialised');
    return;
  }

  log('Google tracking - Report conversion', conversionLabel, properties);

  gtag('event', 'conversion', {
    send_to: `${GA4_STREAMID}/${conversionLabel}`,
    ...properties,
  });
};

const reportGAconversionSwitch = (eventName: string, properties: Record<string, unknown> = {}) => {
  switch (eventName) {
    case 'User > Sign Up':
      return reportConversionEvent(CONVERSION_LABELS.SIGN_UP, {
        transaction_id: properties.email as string,
      });
    case 'Contest > Join Now':
      return reportConversionEvent(CONVERSION_LABELS.ADD_TO_CART, {
        value: properties.entry_fee_usd as number,
        currency: 'USD',
        transaction_id: properties.entry_id as string,
      });
    case 'Commissioner > Create Contest':
      return reportConversionEvent(CONVERSION_LABELS.PURCHASE, {
        value: properties.entry_fee as number,
        currency: 'USD',
        transaction_id: properties.contest_id as string,
      });
    case 'Commissioner > Create Channel':
      return reportConversionEvent(CONVERSION_LABELS.BEGIN_CHECKOUT, {
        transaction_id: properties.channel_slug as string,
      });
    default:
  }
};

const reportGAevent = (eventName: string, properties: Record<string, unknown> = {}) => {
  gtag('event', eventName, {
    ...properties,
  });
};

export default initGtag;
export { isGtagInitialised, setGAuser, reportGAevent, reportGAconversionSwitch };
