import axios from 'axios';

const branchCommonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const branchAuth = {
  branch_key: `${process.env.NEXT_PUBLIC_BRANCH_KEY}`,
};

export const branchioInstanceV1 = axios.create({
  baseURL: 'https://api2.branch.io/v1',
  headers: branchCommonHeaders,
});

export const branchioInstanceV2 = axios.create({
  baseURL: 'https://api2.branch.io/v2',
  headers: branchCommonHeaders,
});

export const goqrInstance = axios.create({
  baseURL: 'https://api.qrserver.com/v1/',
  headers: {
    Accept: 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
    'Content-Type': 'image/png',
  },
});
