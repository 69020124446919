import CommonLocaleEN from '../domains/common/locales/en';
import EntriesLocaleEN from '../domains/entries/locales/en';
import GameLocaleEN from '../domains/game/locales/en';
import GamecenterLocaleEN from '../domains/gamecenter/locales/en';
import LobbyLocaleEN from '../domains/lobby/locale/en';
import MyContestsLocaleEN from '../domains/myContests/locales/en';
import QuickPickLocaleEN from '../domains/quickPick/locales/en';

export default {
  common: CommonLocaleEN,
  detail: {
    header: {
      title: {
        owner: '<link>{{owner}}</link> • Locks',
        adminOwner: 'Splash • Locks',
      },
      info: {
        prize: 'Prizes',
        entry: 'Entry',
        entries: 'Entries',
        deadline: 'Entry deadline',
      },
      actions: {
        copy: 'Copy link',
        new__hasPlural: {
          one: 'Add entry',
          other: 'Add entries',
        },
        makePicks: 'Make picks',
        view: 'View contest',
        edit: 'Edit contest',
      },
    },
    locationInfo: {
      contestTypes: {
        team_pick_em: "Pick'Em",
        team_survivor: 'Survivor',
        player_tier: 'DFS',
        player_category: 'DFS',
        player_one_and_done: 'O&D',
      },
      titleUknown: 'You might be in a restricted location',
      titleKnown: 'You are in a restricted location ({{locationState}})',
      copyLobby:
        'Your current location prevents you from making new entries in {{invalidContestTypes}} Contests. <0>Check our map</0> to view where you can play.',
      copyContestDetail:
        'Your current location prevents you from making new entries in {{invalidContestTypes}} Contests. <0>Check our map</0> to view where you can play, or <1>go back to the lobby</1> to view more contests.',
      copyContestDetailAppend:
        'You are able to continue making picks in the contests that you currently have entries in.',
      checkLocationButtonLabel: 'Recheck location',
    },
    tabs: {
      detail: 'Contest Details',
      entrants: 'Entrants',
      posts: 'Posts',
    },
    about: {
      title: 'About this contest',
      info: {
        sport: 'Sport',
        contestType: 'Contest Type',
        creator: 'Creator',
        adminCreator: 'Splash',
        slate: 'Slate',
        multiEntry: 'Multi-Entry',
        yes: 'Yes ({{entries}})',
        no: 'No',
        entryDeadline: 'Entry Deadline',
        pickDeadline: 'Pick Deadline',
        pickDeadlineValue: {
          game: 'Game Start',
          slate: 'Slate Start',
          tournament: 'Tournament Start',
          dateTime: "ccc '@' h:mm a 'for {{slateName}}'",
          slateForCurrentSlate: 'Slate Start for {{slateName}}',
        },
      },
      game: {
        action: 'View all games',
        modal: {
          title: 'All games',
          nogames: 'No games for this Slate.',
          collapse: {
            show: 'Show more',
            hide: 'Show less',
          },
        },
      },
    },
    appPromo: {
      title: 'Never miss out on the action!',
      subtitle:
        'Download the Splash Sports mobile app and turn on notifications to get pick reminders, the most up-to-date gameplay experience, social features, and additional payment methods!',
    },
    entries: {
      title: 'My entries {{status}}',
      noData: "You don't have any entries.",
      viewAll: 'View all entries',
      error: 'There was an issue while loading your entries',
      entry: {
        title: 'Entry',
        option: {
          edit: 'Edit picks',
          make: 'Make picks',
          view: 'View picks',
          withdraw: 'Withdraw entry',
          editName: 'Edit name',
        },
        withdrawalModal: {
          title: 'Are you sure you want to withdraw this entry ?',
          description:
            'By withdrawing your entry the entry fee will be credited back to your account.',
          cancel: 'Cancel',
          withdraw: 'Withdraw',
          error: {
            title: 'Unable to make a withdrawal to this entry.',
            message: 'Please try again.',
          },
          success: {
            message: 'Withdrawal was successfull.',
          },
        },
      },
    },
    prizes: {
      title: 'Prize payouts',
      modalTitle: 'Payout details',
      tooltip: {
        segmentedPayouts:
          '<text><b>Overall payouts</b> will be made at the end of the contest. <br></br> <b>Segment payouts</b> will be made at the end of each segment.</text>',
        payoutSlatesWeek:
          '<text><b>Overall payouts</b> will be made at the end of the contest. <br></br><b>Weekly payouts</b> will be made at the end of each week.</text>',
        payoutSlatesTournament:
          '<text><b>Overall payouts</b> will be made at the end of the contest. <br></br><b>Tournament payouts</b> will be made at the end of each tournament.</text>',
        payoutSlatesSlate:
          '<text><b>Overall payouts</b> will be made at the end of the contest. <br></br><b>Slate payouts</b> will be made at the end of each slate.</text>',
        overallPayouts:
          '<text>This contest will only have payouts made to the winners at the end of the contest.</text>',
        resizable:
          '<text><flex><b>Resizable prizes</b> <icon></icon> </flex> If the contest does not fill, the payout amounts will be resized.</text>',
        guaranteed:
          '<text><flex><b>Guaranteed prizes</b> <icon></icon> </flex> The payout amount will not change if the contest does not fill.</text>',
        standard:
          '<text><flex><b>Resizable prizes</b> <icon></icon> </flex> The payout does not filled it will be canceled and all entrants will be refunded their entry fee.</text>',
      },
      resizableContest: `If this contest does not fill, the payout amounts will be resized.`,
      standardContest:
        'If this contest does not fill, it will be canceled and all entrants will be refunded their entry fee.',
      guaranteedContest:
        'This is a guaranteed contest. The payout amounts will not change if the contest does not fill.',
      survivorContest:
        'This contest follows a winner-takes-all format, meaning only one participant will be declared the winner and receive the entire prize. In the event of multiple winners, the prize will be divided equally among all the winners.',
      otherContests:
        'In this contest, if two or more entrants end up with the same score or position, they will share the payout amount for the positions they tied for. For instance, if two entrants tie for the first position, they will each receive half of the first place payout amount along with the second place payout amount. The prize will be divided equally among all tied participants.',
      multiplierContest:
        'In this contest, if two or more entrants end up with the same score or position, they will share the payout amount for the positions they tied for.',
      segmentedPayouts: {
        title: 'This contest has an overall contest payout and contest segment payouts.',
        footer:
          'If this contest does not fill, the payout amounts for both the overall contest payout and the contest segment payouts will be resized.',
      },
      resizingMode: {
        resizable: 'Resizable',
        guaranteed: 'Guaranteed',
        standard: 'Non-guaranteed',
      },
      slatePayouts: {
        title: 'This contest has an overall contest payout and payouts for each {{slateLabel}}.',
        footer:
          'If this contest does not fill, the payout amounts for both the overall contest payout and the {{slateLabel}} payouts will be resized.',
      },
      action: {
        view: 'View payout details',
        link: 'View contest resizing rules',
      },
      tabs: {
        overall: 'Overall',
        segments: 'Segments',
        slatePayoutWeek: 'Weeks',
        slatePayoutTournament: 'Tournaments',
        slatePayoutSlate: 'Slates',
      },
    },
    schedule: {
      title: {
        multiSlate: 'Schedule',
        tournament__hasPlural: {
          one: '1 Tournament',
          other: '{{count}} Tournaments',
        },
        week__hasPlural: {
          one: '1 Week',
          other: '{{count}} Weeks',
        },
        slate__hasPlural: {
          one: '1 Slate',
          other: '{{count}} Slates',
        },
      },
      action: {
        segment: 'View full schedule',
        tournament: 'View all tournaments',
        games: 'View all games',
      },
      tabs: {
        overall: 'Segments ({{value}})',
        tournaments: 'Tournaments ({{value}})',
        weeks: 'Weeks ({{value}})',
        slates: 'Slates ({{value}})',
      },
      table: {
        tournamentName: 'Segment {{value}} ({{count}} tournaments)',
        weekName: 'Segment {{value}} ({{count}} weeks)',
        slateName: 'Segment {{value}} ({{count}} slates)',

        singleTournamentName: '{{name}} ({{count}} tournaments)',
        singleWeekName: '{{name}} ({{count}} games)',
        singleSlateName: '{{name}} ({{count}} slates)',
        date: 'Starts {{value}}',
      },
    },
    entrants: {
      title: 'Entrants',
    },
  },
  enterContest: {
    error: {
      default: {
        title: 'Could not join contest',
        message: 'There was an error joining the contest. Please try again later.',
      },
      beginnerOnly: {
        title: 'Beginner Only Contest',
        message:
          'You are no longer a beginner user, and therefore are unable to join this beginner only contest. Check out the <legalLink>house rules</legalLink> for our user classification definitions!',
      },
    },
  },
  game: GameLocaleEN,
  gamecenter: GamecenterLocaleEN,
  lobby: LobbyLocaleEN,
  myContests: MyContestsLocaleEN,
  entries: EntriesLocaleEN,
  quickPick: QuickPickLocaleEN,
};
